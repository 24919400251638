export const ENVIRONMENT_URL = {
  QA_BASE_URL: "https://clean-beirut-egpreyaz1ecq.vapor-farm-d1.com/api/v1/",
  UAT_BASE_URL: "https://blessed-dust-c2zrrmct6xxm.vapor-farm-g1.com/api/v1/",
  PRODUCTION_BASE_URL:
    "https://mellow-moscow-ujuewvep6il7.vapor-farm-e1.com/api/v1/",
  LOCAL_BASE_URL: "http://127.0.0.1:8000/api/v1/",
};
//export const IMAGE_URL = "https://localenvbucketv360.s3.ap-southeast-2.amazonaws.com/";
export const IMAGE_URL = "https://docs.vertex360.io/";
export const BASE_URL = ENVIRONMENT_URL.QA_BASE_URL;

export const REPORT_DOWNLOAD_URL = "cos/case-notes";
export const URL_LOGIN = `${BASE_URL}login`;
export const URL_OTP = `${BASE_URL}send-otp`;
export const URL_CONFIRM_OTP = `${BASE_URL}confirm-otp`;
export const URL_RESEND_OTP = `${BASE_URL}resend-otp`;
export const URL_RESEND_EMAIL_VERFIICATION = `${BASE_URL}resend-email-verification-code`;
export const URL_GET_SLUG_FROM_URL = `${BASE_URL}branding`;

export const URL_LOGOUT = `${BASE_URL}logout`;

//---------------------------SUPER ADMIN --------------------------------//
export const URL_SUPER_ADMIN_CRM_FORM_CATEGORIES = `${BASE_URL}super-admin/form-categories`;
export const URL_SUPER_ADMIN_HRM_FORM_CATEGORIES = `${BASE_URL}super-admin/hrm/form-categories`;
export const URL_SUPER_ADMIN_CRM_FORM_TEMPLATES = `${BASE_URL}super-admin/form-templates-list`;
export const URL_SUPER_ADMIN_HRM_FORM_TEMPLATES = `${BASE_URL}super-admin/hrm/form-templates-list`;
export const URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH = `${BASE_URL}super-admin/form-template/draft-publish`;
export const URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_SP = `${BASE_URL}sp/form-templates/draft-publish`;
export const URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM = `${BASE_URL}super-admin/hrm/form-template/draft-publish`;
export const URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM_SP = `${BASE_URL}sp/hrm/form-templates/draft-publish`;

export const URL_FORM_SEND_EMAIL_FOR_SP = `${BASE_URL}sp/form_data/send-emails`;

//--------------------------- SP ADMIN --------------------------------//

export const URL_SP_DASHBOARD = `${BASE_URL}sp/dashboard`;
export const URL_SP_SETTING = `${BASE_URL}sp/settings`;
export const URL_HRM_CREATE_FOLDER = `${BASE_URL}sp/folder/worker/store`;
export const URL_HRM_UPDATE_FOLDER = `${BASE_URL}sp/folder/worker/update`;
export const URL_HRM_REMOVE_FOLDER = `${BASE_URL}sp/folder/worker/delete`;
export const URL_HRM_FOLDER_LIST = `${BASE_URL}sp/folder/worker/show`;
export const URL_HRM_REMOVE_FILE = `${BASE_URL}sp/file-management/delete-file`;
export const URL_SP_DASHBOARD_INPROGRESS_SHIFTS = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/upcoming-shifts`;

export const URL_PARTICIPANT_CREATE_FOLDER = `${BASE_URL}sp/folder/participant/store`;

export const URL_PARTICIPANT_UPDATE_FOLDER = `${BASE_URL}sp/folder/participant/update`;
export const URL_PARTICIPANT_REMOVE_FOLDER = `${BASE_URL}sp/folder/participant/delete`;
export const URL_PARTICIPANT_FOLDER_LIST = `${BASE_URL}sp/folder/participant/show`;
export const URL_HRM_PARTICIPANT_FOLDER_LIST = `${BASE_URL}hrm/folder/participant/show`;
export const URL_PARTICIPANT_REMOVE_FILE = `${BASE_URL}sp/file-management/delete-file`;

export const URL_WORKER_FOLDER_LIST = `${BASE_URL}hrm/folder/worker/show`;
export const URL_WORKER_CREATE_FOLDER = `${BASE_URL}hrm/folder/worker/store`;
export const URL_WORKER_UPDATE_FOLDER = `${BASE_URL}hrm/folder/worker/update`;
export const URL_WORKER_REMOVE_FOLDER = `${BASE_URL}hrm/folder/worker/delete`;
export const URL_WORKER_REMOVE_FILE = `${BASE_URL}hrm/file-management/delete-file`;

export const URL_SP_PARTICIPANTS = `${BASE_URL}sp/participants-list`;
export const URL_SP_WORKER = `${BASE_URL}sp/participant-setting/worker-list`;
export const URL_SP_FEEDBACK = `${BASE_URL}sp/feedback`;
export const URL_SP_COMPLAINT = `${BASE_URL}sp/complaint`;
export const URL_SP_QUESTION = `${BASE_URL}ask-question`;
export const URL_SP_FORM_CATEGGORES = `${BASE_URL}sp/form-categories`;
export const URL_SP_CRM_FORM_TEMPLATES = `${BASE_URL}sp/form-templates`;
export const URL_SP_HRM_FORM_TEMPLATES = `${BASE_URL}sp/hrm/form-templates`;
export const URL_SP_DOCUMENTS = `${BASE_URL}sp/documents`;
export const URL_SP_DELETE_DOCUMENTS = `${BASE_URL}sp/delete-document`;
export const URL_SP_UPLOAD_DOCUMENTS = `${BASE_URL}sp/file-management/upload-document`;
export const URL_SP_EMPLOYEE_DOCUMENTS = `${BASE_URL}sp/worker-docs`;
export const URL_SP_UPDATE_DOCUMENTS = `${BASE_URL}sp/update-document`;
export const URL_SP_RISK_RATING_TAB_LIST = `${BASE_URL}sp/participant/submitted-forms`;
export const URL_SP_CREATE_MANAGER = `${BASE_URL}sp/register-manager`;
export const URL_SP_MANAGER_ROLE = `${BASE_URL}sp/update-managers-role`;
export const URL_SP_LIST_MANAGER = `${BASE_URL}sp/managers-list`;
export const URL_SP_SUBMITTED_FORMS_LIST = `${BASE_URL}sp/participant/submitted-forms`;
export const URL_SP_HRM_SUBMITTED_FORMS_LIST = `${BASE_URL}sp/employee/submitted-forms`;
export const URL_SP_POLICIES_DELETE = `${BASE_URL}sp/delete-policy`;
export const URL_SP_UPLOAD_DOCUMENT = `${BASE_URL}sp/upload-document`;

export const URL_SP_DELETE_CASE_NOTE_ATTACHMENT = `${BASE_URL}sp/case-note/attachment`;

// Staff Compliance
export const URL_SP_STAFF_COMPLIANCE_SETTING = `${BASE_URL}sp/worker-compliance-setting`;
export const URL_SP_STAFF_COMPLIANCE_SETTING_UPDATE = `${BASE_URL}sp/worker-compliance-setting/update`;

// Rostering Settings
export const URL_SP_ROSTERING_SETTING = `${BASE_URL}sp/general-setting`;
export const URL_SP_ROSTERING_SETTING_UPDATE = `${BASE_URL}sp/general-setting/update`;

//Stirpe
export const URL_SP_STRIPE_PRICING_TABLE_LIST = `${BASE_URL}sp/stripe/get/pricing`;
export const URL_SP_STRIPE_ATTACH_PAYMENT_METHOD = `${BASE_URL}sp/stripe/attach/customer/payment-method`;
export const URL_SP_STRIPE_LIST_ALL_CARD = `${BASE_URL}sp/stripe/customer/payment-methods`;
export const URL_SP_STRIPE_BUY_PLAN = `${BASE_URL}sp/stripe/create/subscription`;
export const URL_SP_STRIPE_GET_DEFAULT_PAYMENT_METHOD = `${BASE_URL}sp/stripe/get/payment-settings`;
export const URL_SP_STRIPE_CURRENT_SUBSCRIPTION = `${BASE_URL}sp/stripe/get/subscription`;
export const URL_SP_STRIPE_MAKE_CARD_DEFAULT = `${BASE_URL}sp/stripe/make-default/customer/payment-method`;
export const URL_SP_STRIPE_DETTACH_CARD = `${BASE_URL}sp/stripe/detach/customer/payment-method`;
export const URL_SP_STRIPE_INVOICES = `${BASE_URL}sp/stripe/get/all-invoices`;
export const URL_SP_STRIPE_RETRY_PAYMENT = `${BASE_URL}sp/stripe/payment/retry-charge`;
export const URL_SP_STRIPE_COUPON_INFO = `${BASE_URL}sp/stripe/coupon/info`;

//--------------------------- PARTICIPANT --------------------------------//

export const URL_GET_PARTICIPANT = `${BASE_URL}sp/participants`;
export const URL_PARTICIPANT_SUPPORT_PLAN = `${BASE_URL}sp/participant/support-plan`;
export const URL_PARTICIPANT_SETTING = `${BASE_URL}sp/participants/setting-update`;
export const URL_PARTICIPANT_APPOINTMENT = `${BASE_URL}sp/participant-appointment`;

//---------------------------HRM--------------------------------//

export const URL_HRM_FORM_CATEGORIES = `${BASE_URL}sp/hrm/form-categories`;
export const URL_SP_APPLICANTS = `${BASE_URL}hrm/recruitment-stages/applicants`;
export const URL_HRM_DOCUMENTS = `${BASE_URL}hrm/documents`;
export const URL_HRM_SUBMITTED_FORMS_LIST = `${BASE_URL}hrm/participant/submitted-forms`;
export const URL_HRM_APPOINTMENT = `${BASE_URL}hrm/participant-appointment`;
export const URL_HRM_GET_PARTICIPANT = `${BASE_URL}hrm/participants`;
export const URL_HRM_GET_TEAM_MANAGMENT = `${BASE_URL}sp/employee-list`;
export const URL_HRM_WORKER_PROFILE_DETAIL = `${BASE_URL}sp/employee-profile`;
export const URL_HRM_WORKER_GENERAL_SETTING = `${BASE_URL}hrm/worker-settings`;
export const URL_HRM_WORKER_STATUS_UPDATE = `${BASE_URL}hrm/employee/update-status`;
export const URL_EMPLOYEE_HRM_FORM_CATEGORIES = `${BASE_URL}worker/hrm/form-categories`;
export const URL_EMPLOYEE_HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY = `${BASE_URL}worker/hrm/submitted-forms`;

export const URL_HRM_DELETE_CASE_NOTE_ATTACHMENT = `${BASE_URL}hrm/case-note/attachment`;
export const URL_HRM_FORM_EMAIL_SEND = `${BASE_URL}sp/hrm-form_data/send-emails`;
//folder structure
export const URL_HRM_PARTICIPANT_CREATE_FOLDER = `${BASE_URL}hrm/folder/participant/store`;

//Ex...

export const URL_GET_FILE = `${BASE_URL}sp/file-management/view-file`;
export const URL_WORKER_GET_FILE = `${BASE_URL}hrm/file-management/view-file`;

//---------------------------COS--------------------------------//

export const URL_DASHBOARD_DATA = `${BASE_URL}cos/dashboard-stats`;
export const URL_GET_COS_PARTICIPANT = `${BASE_URL}cos/participant`;
export const URL_GET_COS_CASE_NOTE_DETAIL = `${BASE_URL}cos/case-notes/show`;
export const CATALOG_SERVICES = `${BASE_URL}cos/price-catalogs`;
export const CATALOG_SERVICES_SUB_CATEGORY = `${BASE_URL}cos/states`;
export const URL_CATALOG_VERSION = `${BASE_URL}cos/version-list`;
export const URL_UPDATE_CASE_NOTE = `${BASE_URL}cos/case-notes/update`;
export const URL_FUNDING_SETUP_CREATE = `${BASE_URL}cos/funding/store`;
export const URL_FUNDING_SETUP_UPDATE = `${BASE_URL}cos/funding/update`;
export const URL_FUNDING_SETUP_PARTICIPAANT = `${BASE_URL}cos/funding/participant`;
export const URL_FUNDING_SETUP_PARTICIPAANT_FOR_COS = `${BASE_URL}cos/funding/cos-participant`;
export const URL_SELECTED_FUNDING_SETUP = `${BASE_URL}cos/funding/show`;
export const URL_PLAN_CATAGORIES = `${BASE_URL}cos/service-sub-categories`;
export const URL_PLAN_BUDGET = `${BASE_URL}cos/plan-budget/store`;
export const URL_PLAN_BUDGET_UPDATE = `${BASE_URL}cos/plan-budget/update`;
export const URL_PLAN_BUDGET_LIST = `${BASE_URL}cos/plan-budget`;
export const URL_SERVICE_CATEGORY = `${BASE_URL}cos/sp-category-budget`;
export const URL_EXTERNAL_SP = `${BASE_URL}cos/external-sp`;
export const URL_PLAN_BUDGET_CAT_AND_SUB_CAT = `${BASE_URL}cos/plan-budget-cat`;
export const URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_UPDATE = `${BASE_URL}cos/sp-category-budget-update`;
export const URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET = `${BASE_URL}cos/sp-category-budget`;
export const URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_LIST = `${BASE_URL}cos/sp-category-budget-list`;
export const URL_SEARCH_CATALOG_SERVICES = `${BASE_URL}cos/service-support-budget/service-search`;
export const URL_FUNDING_NOTES_LIST = `${BASE_URL}cos/funding-notes`;
export const URL_FUNDING_NOTES_LIST_UPDATE = `${BASE_URL}cos/funding-notes-update`;
export const URL_SERVICE_SUPPORT_BUDGET_STORE = `${BASE_URL}cos/service-support-budget/store`;
export const URL_SERVICE_SUPPORT_BUDGET_UPDATE = `${BASE_URL}cos/service-support-budget/update`;
export const URL_SERVICE_SUPPORT_BUDGET_LIST = `${BASE_URL}cos/service-support-budget`;
export const URL_SERVICE_SUPPORTS_FOR_DELIVERED_SERVICE = `${BASE_URL}cos/delivered-service/fetch-plan-budget-category-with-services`;
export const URL_COS_PARTICIPANT_LIST = `${BASE_URL}cos/participants`;
export const URL_SUPPORT_COORDINATOR = `${BASE_URL}cos/support-coordinates`;
export const URL_DELIVERED_SERVICE_STORE = `${BASE_URL}cos/delivered-service/store`;
export const URL_MANUAL_DELIVERED_SERVICE_STORE = `${BASE_URL}cos/delivered-service/store-manual`;
export const URL_DELIVERED_SERVICE = `${BASE_URL}cos/delivered-service`;
export const URL_MANUAL_DELIVERIES = `${BASE_URL}cos/delivered-service/manual-delivery`;
export const URL_CREATE_CASENOTES = `${BASE_URL}cos/case-notes`;
export const URL_GET_CASENOTES = `${BASE_URL}cos/case-notes`;
export const URL_UPDATE_CLIENT_TASK = `${BASE_URL}cos/client-tasks-update`;
export const URL_CREATE_CLIENT_TASKS = `${BASE_URL}cos/client-tasks`;
export const URL_DELETE_CLIENT_TASKS = `${BASE_URL}cos/client-tasks/`;
export const URL_GET_SINGLE_SUPPORT_TRACK = `${BASE_URL}cos/case-notes/support-track`;
export const URL_GET_CASE_NOTES_CLIENTS = `${BASE_URL}cos/case-notes/clients`;
export const URL_GET_CASE_NOTES_HISTORY_List = `${BASE_URL}cos/case-notes/history`;
export const URL_GET_CASE_NOTES_LIST_FOR_NDIS_CLAIM = `${BASE_URL}cos/case-notes/ndis-claims`;
export const URL_ADD_CASE_NOTES_TO_NDIS_CLAIM_LIST = `${BASE_URL}cos/case-notes/create-claims`;
export const URL_GET_CLAIMED_CASE_NOTES_LIST = `${BASE_URL}cos/case-notes/created-claims-list`;
export const URL_GET_CASE_NOTES_REVIEW = `${BASE_URL}cos/case-notes/ndis-claim-review`;
export const URL_GEL_REPORTING = `${BASE_URL}cos/reporting`;
export const URL_GET_SERVICE_PROVIDER_SERCH_RESULT = `${BASE_URL}cos/case-notes/external-sp`;
export const URL_CREATE_COS_INVOICES = `${BASE_URL}cos/case-notes/create-cos-invoices`;
export const URL_GET_COS_INVOICES = `${BASE_URL}cos/case-notes/cos-invoices`;
export const URL_GET_CREATED_COS_INVOICES = `${BASE_URL}cos/case-notes/created-cos-invoices-list`;
export const URL_GET_CREATED_COS_INVOICES_PREVIEW_LIST = `${BASE_URL}cos/case-notes/cos-invoice-review`;
export const URL_CHANGE_NDIS_CLAIM_ACTIONS = `${BASE_URL}cos/case-notes/ndis-claim-actions`;
export const URL_CHANGE_NDIS_INVOICE_CLAIM_ACTIONS = `${BASE_URL}cos/case-notes/cos-invoice-actions`;
export const URL_COS_CATEGORY_SETTING_DATA_COS = `${BASE_URL}cos/client-task-category-list`;
export const URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_COS = `${BASE_URL}cos/client-task-category`;
export const URL_COS_CONTACT_SETTING_DATA_COS = `${BASE_URL}cos/contact-types-list`;
export const URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_COS = `${BASE_URL}cos/contact-types`;
export const URL_COS_PRIORITY_SETTING_DATA_COS = `${BASE_URL}cos/cos-priority-list`;
export const URL_COS_PERIORITY_SETTING_CREATE_UPDATE_COS = `${BASE_URL}cos/cos-priority`;
export const URL_GET_CATALOG_PRICE_AGAINST_STATE = `${BASE_URL}cos/service-support-budget/price-catalog-price-against-state`;

export const URL_GET_SUPPORT_TRACK_LIST = `${BASE_URL}sp/cos/case-notes/list-for-support-track`;

//Rostering Url's
export const URL_ROSTERING_WORKER_AND_PARTICIPANT_LIST = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/worker_participant_list`;
export const URL_ROSTERING_SHIFT = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/shift`;
export const URL_ROSTERING_SHIFT_STATUS_CHANGE = `${BASE_URL}sp/rostering/change_shift_status`;
export const URL_ROSTERING_SHIFT_COPY = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/copy_shift`;
export const URL_ROSTERING_WORKER_TIMESHEETS = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/worker_time_sheet`;
export const URL_ROSTERING_PARTICIPANT_LIST_FOR_TIMESHEETS = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/participants_list_by_timesheet`;
export const URL_ROSTERING_TIMESHEET_COMMENTS = `${BASE_URL}sp/rostering/timesheet_comment_list`;
export const URL_ROSTERING_CREATE_TIMESHEET_COMMENT = `${BASE_URL}sp/rostering/create_comment`;
export const URL_ROSTERING_UPDATE_TIMESHEET_DATA = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/timesheet`;
export const URL_ROSTERING_CHANGE_TIMESHEET_STATUS = `${BASE_URL}sp/rostering/change_status`;
export const URL_ROSTERING_LATEST_SHIFT_PARTICIPANTS = `${BASE_URL}sp/rostering/latest-shift-participant`;
export const URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/time-sheet-export/worker_time_sheet`;

// url for targeting participant nots.
export const URL_PARTICIPANTS_SHIFT_NOTES = `${BASE_URL.replace(
    /v1/,
    "v2"
)}sp/rostering/get_shift_notes`;

// url for targeting participant notes, access for standard user

export const URL_PARTICIPANTS_SHIFT_NOTES_STANDARD_USER = `${BASE_URL.replace(
    /v1/,
    "v2"
)}hrm/get_shift_notes`;

export const URL_ROSTERING_SINGLE_TIMESHEET_DETAILS = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/time-sheet-export/get-single-time-sheet`;
export const URL_ROSTERING_CHECK_PAYROLL_AUTHENTICATION = `${BASE_URL}sp/payroll/check-authentication`;
export const URL_ROSTERING_XERO_LOGIN = `${BASE_URL.replace(
  /\/api\/v1\//,
  "/"
)}xero/login`;
export const URL_ROSTERING_QUICK_BOOKS_LOGIN = `${BASE_URL.replace(
  /\/api\/v1\//,
  "/"
)}quick-books/login`;
export const URL_ROSTERING_MYOB_LOGIN = `${BASE_URL.replace(
  /\/api\/v1\//,
  "/"
)}myob/login`;
export const URL_QUICK_BOOKS_EMPLOYEE = `${BASE_URL}sp/payroll/get-employees`;
export const URL_QUICK_BOOKS_SYNC_EMPLOYEE = `${BASE_URL}sp/payroll/quick-books/sync-employees`;
export const URL_ROSTERING_EXPORT_TIMESHEET_TO_PAYROLL = `${BASE_URL}sp/payroll/create/timesheet`;
export const URL_PAYROLL_CONNECT = `${BASE_URL}sp/payroll/connect`;
export const URL_PAYROLL_QUICKBOOKS_BUSINESSES = `${BASE_URL}sp/payroll/quick-books/get-businesses`;
export const URL_PAYROLL_MYOB_BUSINESSES = `${BASE_URL}sp/payroll/myob/get-businesses`;
export const URL_PAYROLL_MYOB_EMPLOYEES = `${BASE_URL}sp/payroll/myob/get-employees`;
export const URL_PAYROLL_SYNC_MYOB_EMPLOYEES = `${BASE_URL}sp/payroll/myob/sync-employees`;
export const URL_PAYROLL_MYOB_CATEGORIES = `${BASE_URL}sp/payroll/myob/get-categories`;
export const URL_PAYROLL_SYNC_MYOB_CATEGORIES = `${BASE_URL}sp/payroll/myob/sync-categories`;

export const URL_ROSTERING_RECURRING_SHIFT = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/recurring_shift`;
export const URL_ROSTERING_FORTNIGHTLY_COPY_SHIFT = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/copy_shifts`;
export const URL_ROSTERING_INVOICING_GET_ITEM_NUMBER_ON_TIMESHEET = `${BASE_URL.replace(
  /v1/,
  "v2"
)}roster-invoices/get-item-detail`;
export const URL_ROSTERING_INVOICING_GET_TRAVEL_DETAILS = `${BASE_URL.replace(
  /v1/,
  "v2"
)}roster-invoices/get-travel-detail`;
export const URL_ROSTERING_GET_RECURRING_SHIFT_ON_EDIT_MESSAGE = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/shift/impacted-count`;

//Roster Invoicing
export const URL_GET_ROSTER_INVOICES = `${BASE_URL}roster-invoices/timesheets`;
export const URL_CREATE_ROSTER_INVOICES = `${BASE_URL}roster-invoices/create`;
export const URL_ROSTER_INVOICES_LIST = `${BASE_URL}roster-invoices/list`;
export const URL_ROSTERING_INVOICE_DETAIL = `${BASE_URL}roster-invoices/detail`;
export const URL_ROSTERING_INVOICE_STATUS_UPDATE = `${BASE_URL}roster-invoices/invoice`;
export const URL_ROSTER_INVOICING_TIMESHEET_BREAKDOWN = `${BASE_URL.replace(
  /v1/,
  "v2"
)}roster-invoices/get-breakdown`;

export const URL_ROSTERING_SHIFT_CHECK_OVERLAPP = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/shift/check-overlapping`;
export const URL_ROSTERING_TIMESHEET_CHECK_OVERLAPPING = `${BASE_URL.replace(
  /v1/,
  "v2"
)}sp/rostering/timesheet/check-overlapping`;

//Roles and permissions
export const URL_ROLE_AND_PERMISSION = `${BASE_URL}sp/roles-and-permission/role`;
export const URL_ROLE_AND_PERMISSION_LIST = `${BASE_URL}sp/roles-and-permission/roles`;
export const URL_ROLE_AUTHENTICATE_USER = `${BASE_URL}get-role-authenticate-user`;
// Reporting Module
export const URL_REPORTING_PARTICIPANT_REPORTS = `${BASE_URL}sp/reporting/participant-report`;
export const URL_REPORTING_WORKER_REPORTS = `${BASE_URL}sp/reporting/support-worker-report`;
export const URL_REPORTING_ROC_REPORTS = `${BASE_URL}sp/reporting/roc-report`;
export const URL_REPORTING_TIMESHEET_REPORTS = `${BASE_URL}sp/reporting/time-sheet-report`;
export const URL_REPORTING_GET_SP_USERS_LIST = `${BASE_URL}sp/reporting/sp-users-list`;
export const URL_REPORTING_CASE_NOTES_REPORTS = `${BASE_URL}sp/reporting/participant-casenote-report`;
export const URL_REPORTING_WORKER_EXPIRING_DOCUMENT_REPORTS = `${BASE_URL}sp/reporting/worker-documents-report`;
export const URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS = `${BASE_URL}sp/reporting/participant-documents-report`;
export const URL_REPORTING_KYP_REPORTS = `${BASE_URL}sp/reporting/kyp-report`;
export const URL_REPORTING_FEEDBACK_REPORTS = `${BASE_URL}sp/reporting/feedback-report`;
export const URL_REPORTING_COMPLAINTS_REPORTS = `${BASE_URL}sp/reporting/complaint-report`;
export const URL_REPORTING_ROC_WEEKLY_REPORTS = `${BASE_URL}sp/reporting/roc-weekly-overtime-report`;
export const URL_REPORTING_COS_REPORTS = `${BASE_URL}sp/reporting/cos-casenote-report`;
export const URL_REPORTING_COS_FUNDING_REPORTS = `${BASE_URL}sp/reporting/cos-funding-report`;
export const URL_REPORTING_SUMMARY_INVOICING_REPORTS = `${BASE_URL}sp/reporting/invoice-summary-report`;
export const URL_REPORTING_DETAILED_INVOICING_REPORTS = `${BASE_URL}sp/reporting/invoice-detail-report`;
export const URL_REPORTING_GET_INVOICING_SUPPORT_ITEM = `${BASE_URL}sp/reporting/invoice-detail-service-item`;
export const URL_REPORTING_INCIDENT_REPORTS = `${BASE_URL}sp/reporting/incident-report`;
export const URL_REPORTING_RISK_CATEGORY_IN_RISK_ASSESSMENT_REPORTS = `${BASE_URL}sp/reporting/risk-assessment-categories`;
export const URL_REPORTING_RISK_ASSSESSMENT_REPORTS = `${BASE_URL}sp/reporting/risk-assessment-report`;
//Team Lead Module
export const URL_TEAM_LEAD_GET_REGION_AND_MANAGERS = `${BASE_URL}sp/team-leader/regions/create`;
export const URL_TEAM_LEAD_CREATE_REGION = `${BASE_URL}sp/team-leader/regions/store`;
export const URL_TEAM_LEAD_GET_REGION_LIST = `${BASE_URL}sp/team-leader/regions`;
export const URL_TEAM_LEAD_GET_REGION_BY_UUID = `${BASE_URL}sp/team-leader/regions/edit`;
export const URL_TEAM_LEAD_UPDATE_REGION = `${BASE_URL}sp/team-leader/regions/update`;
export const URL_TEAM_LEAD_REGIONS_STATUS = `${BASE_URL}sp/team-leader/regions/status`;
export const URL_TEAM_LEAD_GET_MANAGERS_REGIONS_LIST = `${BASE_URL}sp/team-leader/regions/sp-manager`;
export const URL_TEAM_LEAD_MANAGER_REGION_STATUS_UPDATE = `${BASE_URL}sp/team-leader/regions/status`;

// Sil Accommodation URL's
export const URL_SIL_PROPERTY_TYPE_LIST = `${BASE_URL}sil/property-type/list`;
export const URL_SIL_SDA_TYPE_LIST = `${BASE_URL}sil/sda-type/list`;
export const URL_SIL_ACCOMMODATION_CREATE = `${BASE_URL}sil/home/create`;
export const URL_SIL_ACCOMMODATION_LIST = `${BASE_URL}sil/home/list`;
export const URL_SIL_ACCOMMODATION_QUICK_VIEW = `${BASE_URL}sil/home/quick-view`;
export const URL_SIL_ACCOMMODATION_DETAIL = `${BASE_URL}sil/home/detail`;
export const URL_SIL_ACCOMMODATION_SETTINGS_UPDATE = `${BASE_URL}sil/home/update`;
export const URL_SIL_ACCOMMODATION_UPLOAD_IMAGE = `${BASE_URL}sil/home/image`;
export const URL_SIL_ACCOMMODATION_UPLOAD_ATTACHMENT = `${BASE_URL}sil/home/attachments`;
export const URL_SIL_ACCOMMODATION_DELETE_IMAGE = `${BASE_URL}sil/home/image/delete`;

export const URL_SIL_ROOMS_LIST = `${BASE_URL}sil/home/room/list`;
export const URL_SIL_ROOM_DETAIL = `${BASE_URL}sil/home/room/detail`;
export const URL_SIL_ROOM_CREATE = `${BASE_URL}sil/home/room/create`;
export const URL_SIL_ROOM_DELETE = `${BASE_URL}sil/home/room/delete`;
export const URL_SIL_ROOM_UPDATE = `${BASE_URL}sil/home/room/update`;
export const URL_SIL_ROOM_NAME_UPDATE = `${BASE_URL}sil/home/room/updateName`;
export const URL_SIL_ROOM_ATTACHMENTS = `${BASE_URL}sil/home/room/attachments`;
export const URL_SIL_ROOM_ATTACHMENT_DELETE = `${BASE_URL}sil/home/room/attachments/delete`;

//Sil Form
export const URL_SIL_FORM_CATEGORIES = `${BASE_URL}sil/form/categories`;
export const URL_SIL_FORM_LIST = `${BASE_URL}sil/form/list`;
export const URL_SIL_FORM_DRAFT_TO_PUBLISH = `${BASE_URL}sil/form/draft-publish`;
export const URL_SIL_ACCOMMODATION_FORM_LIST_BY_CATEGORY = `${BASE_URL}sil/form/category-forms`;

export const URL_SIL_ACCOMMODATION_SUBMITTED_FORM_LIST = `${BASE_URL}sil/home/form/list`;
export const URL_SIL_ACCOMMODATION_SUBMITTED_FORM_DETAIL = `${BASE_URL}sil/home/form/detail`;
export const URL_SIL_ACCOMMODATION_SUBMITTED_FORM_UPDATE = `${BASE_URL}sil/home/form/update`;
export const URL_SIL_ACCOMMODATION_FORM_DATA_BY_SLUG = `${BASE_URL}sil/home/form/by-slug`;

//Sil Case Notes
export const URL_SIL_ACCOMMODATION_CASE_NOTES_LIST = `${BASE_URL}sil/home/note/list`;
export const URL_SIL_ACCOMMODATION_CASE_NOTE_CREATE = `${BASE_URL}sil/home/note/create`;
export const URL_SIL_ACCOMMODATION_CASE_NOTE_UPDATE = `${BASE_URL}sil/home/note/update`;
export const URL_SIL_ACCOMMODATION_CASE_NOTE_DELETE = `${BASE_URL}sil/home/note/delete`;
export const URL_SIL_ACCOMMODATION_CASE_NOTE_ATTACHMENT_DELETE = `${BASE_URL}sil/home/note/attachment`;

//Sil Documents
export const URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_LIST = `${BASE_URL}sil/home/document/show`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_CREATE = `${BASE_URL}sil/home/document/store`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_UPDATE = `${BASE_URL}sil/home/document/update`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_FILE_UPLOAD = `${BASE_URL}sil/home/document/upload-document`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_VIEW_FILE = `${BASE_URL}sil/home/document/view-file`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_FILE_REMOVE = `${BASE_URL}sil/home/document/delete-file`;
export const URL_SIL_ACCOMMODATION_DOCUMENT_FOLDER_REMOVE = `${BASE_URL}sil/home/document/delete`;
